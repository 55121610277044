import React, { Component } from 'react'
import he from 'he'

import { Facebook, Twitter, LinkedIn, Email } from './icons'

class SocialShare extends Component {

  render() {
    const shareLink = typeof window !== 'undefined' && window.location

    let { title } = this.props

    return (
      <div className='social-share'>
        <h5>Share this post</h5>
        <div className='social-share__buttons'>
          <a href={`//www.facebook.com/sharer/sharer.php?u=${shareLink}`}><Facebook /></a>
          <a href={`//www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${he.decode(title)}`}><LinkedIn /></a>
          <a href={`mailto:?&subject=${he.decode(title)}&body=${shareLink}`}><Email /></a>
        </div>
      </div>
    )
  }
}

export default SocialShare
