import React, { Component } from 'react'

import { Close } from '../icons'

class VideoEmbed extends Component {
  state = {
    active: false,
  }

  renderImage(image) {
    if (!image || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  //<div className="post-block__video-wrapper" dangerouslySetInnerHTML={{__html: this.props.video }} />

  render() {
    let { video, poster, title } = this.props
    let { active } = this.state

    return (
      <div className="post-block__video-embed">
        <picture onClick={() => this.setState({ active: true })}>
          { this.renderImage(poster) }
          <svg xmlns="http://www.w3.org/2000/svg" width="73" height="74" viewBox="0 0 73 74">
            <g transform="translate(-798 -1223)">
              <ellipse cx="36.5" cy="37" rx="36.5" ry="37" transform="translate(798 1223)" fill="#fff" />
              <path d="M297.638,420.714V393.232a1.134,1.134,0,0,1,1.716-.973L322.3,406a1.134,1.134,0,0,1,0,1.946l-22.95,13.741A1.134,1.134,0,0,1,297.638,420.714Z" transform="translate(527.362 852.904)" fill="#fff" />
            </g>
          </svg>
        </picture>

        <div className={`post-block__video-embed__modal ${active ? 'active' : ''}`}>
          <h3>{ title }</h3>
          <div className='post-block__video-embed__video' dangerouslySetInnerHTML={{__html: active && video }} />
          <span className="close" onClick={() => this.setState({ active: false })}><Close colour="#737373" /></span>
        </div>
      </div>
    )
  }
}

export default VideoEmbed
