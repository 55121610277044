import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'
import moment from 'moment'

import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import RelatedPosts from '../components/related-posts'
import Content from '../components/post/content'
import Image from '../components/post/image'
import VideoEmbed from '../components/post/video-embed'
import VideoId from '../components/post/video-id'
import BlockQuote from '../components/post/blockquote'
import SocialShare from '../components/social-share'

import { ArrowDown, Close } from '../components/icons'

class PostTemplate extends Component {

  lastScroll = 0

  state = {
    visible: false,
    scrolled: false,
    filter: 'All'
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  scrollDetect = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      if (!scrolled) {
        this.setState({ scrolled: true })
      }
    } else {
      if (scrolled) {
        this.setState({ scrolled: false })
      }
    }
    this.lastScroll = currentScroll
  }

  _toggleFilter = () => {
    if ( this.state.visible === true ) {
      this.setState({visible: false})
    } else {
      this.setState({visible: true})
    }
  }

  renderBlock(param, el, i) {
    let block = {
      'WordPressAcf_content': (el, i) => (<Content {...el} key={i} />),
      'WordPressAcf_image': (el, i) => (<Image {...el} key={i} />),
      'WordPressAcf_video': (el, i) => (<VideoEmbed {...el} key={i} />),
      'WordPressAcf_blockquote': (el, i) => (<BlockQuote {...el} id={i} key={i} />),
      'WordPressAcf_video_id': (el, i) => (<VideoId {...el} key={i} />),
    }[param]

    if (!block) return

    return block(el, i)
  }

  _renderPost = (data, i=0) => {
    let featuredImage = data.acf.featured_image && data.acf.featured_image.localFile && data.acf.featured_image.localFile.childImageSharp && data.acf.featured_image.localFile.childImageSharp.original.src
    let date = moment(data.date).format('MMM, YYYY')
    let author = data.acf.author_name ? data.acf.author_name : data.author
    /* console.log(data.acf.posts_list)
     * console.log('here') */
    return (
      <article className="article" key={i}>
        <div className='article__wrapper container'>
          <div className="article__sidebar">
            <div className="article__sticky">
              <Fade bottom distance='40px'>
                <span className="article__date">{author} | {date}</span>
                <h1>{ he.decode(data.title) }</h1>
                <div className="article__excerpt" dangerouslySetInnerHTML={{__html: data.excerpt }} />
              </Fade>
              <Fade bottom distance='40px'>

                <div className="article__category">
                  {(data.sector) ? <h4>Sector</h4> : ''}
                  { data.sector &&
                    <ul>
                      { data.sector.map((el, i) => <li key={i}><Link to={`/services/${el.slug}`}>{el.name}</Link></li>) }
                    </ul>
                  }
                </div>
                <div className="article__category">

                  {(data.categories) ? <h4>Discipline</h4> : ''}
                  { data.categories &&
                    <ul>
                      { data.categories.map((el, i) => <li key={i}><Link to={`/services/${el.slug}`}>{el.name}</Link></li>) }
                    </ul>
                  }
                </div>
              </Fade>
            </div>
          </div>
          <div className="article__body">
            { featuredImage &&
              <Fade bottom distance='40px'>
                <picture className="article__featured-image">
                  <img src={featuredImage} alt={data.title} />
                </picture>
              </Fade>
            }
            <div className='article__inner'>
              { data.acf.content_blocks_post && data.acf.content_blocks_post.map((el, i) => {
                return this.renderBlock(el.__typename, el, i)
              }) }
            </div>
            <Fade bottom distance='40px'>
              <div className="article__category">
                {(data.sector) ? <h4>Sector</h4> : ''}
                { data.sector &&
                  <ul>
                    { data.sector.map((el, i) => <li key={i}><Link to={`/blog/sector/${el.name}`}>{el.name}</Link></li>) }
                  </ul>
                }
              </div>
              <div className="article__category">
                {(data.categories) ? <h4>Sector</h4> : ''}
                { data.categories &&
                  <ul>
                    { data.categories.map((el, i) => <li key={i}><Link to={`/blog/discipline/${el.name}`}>{el.name}</Link></li>) }
                  </ul>
                }
              </div>
            </Fade>
            <Fade bottom distance='40px'>
              <SocialShare title={data.title} />
            </Fade>
          </div>
        </div>
      </article>
    )
  }

  render() {
    let { visible, filter, scrolled } = this.state
    let data = this.props.data
    let featuredImage = data.wordpressPost.acf.featured_image && data.wordpressPost.acf.featured_image.localFile && data.wordpressPost.acf.featured_image.localFile.childImageSharp && data.wordpressPost.acf.featured_image.localFile.childImageSharp.original.src
    let meta = [{ property: 'og:image', content: featuredImage }]
    return (
      <>
        <SEO title={he.decode(data.wordpressPost.yoast_meta.yoast_wpseo_title)} bodyClass="single-post" description={data.wordpressPost.yoast_meta.yoast_wpseo_metadesc} meta={meta} />
        <div className="blogs blogs--single">
          <div className={`blogs__filter ${scrolled ? 'scrolled' : ''}`}>
            <div className="blogs__inner container">
              <div className="col">
                <h4 onClick={this._toggleFilter}>Filter: <span>{this.state.filter}</span> <ArrowDown colour="#000" /></h4>
              </div>
            </div>
          </div>
          <div className={`${visible ? 'blogs__filter-drop active' : 'blogs__filter-drop'}  ${scrolled ? 'scrolled' : ''}`}>
            <span className="close" onClick={this._toggleFilter}><Close colour="#737373" /></span>
            <div className="blogs__inner container">
              <div className="col">
                <h5>Filter: <span>{filter}</span></h5>
              </div>
              { data.allWordpressCategory.edges && data.allWordpressCategory.edges.length > 0 &&
                <div className="col">
                  <h4>Discipline</h4>
                  <ul>
                    {
                      data.allWordpressCategory.edges.map((el, i) => {
                        let props = {
                          to: `/blog/discipline/${el.node.slug}`
                        }
                        return (
                          <li key={i}>
                            <Link {...props}>{ el.node.name }</Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              }
              { data.allWordpressWpSector.edges && data.allWordpressWpSector.edges.length > 0 &&
                <div className="col">
                  <h4>Sector</h4>
                  <ul>
                    {
                      data.allWordpressWpSector.edges.map((el, i) => {
                        let props = {
                          to: `/blog/sector/${el.node.slug}`
                        }
                        return (
                          <li key={i}>
                            <Link {...props}>{ el.node.name }</Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        { this._renderPost(data.wordpressPost) }
        <RelatedPosts posts = {data.wordpressPost.acf.posts_list } />
        <Subscribe />
      </>
    )
  }

}

export default PostTemplate

export const postQuery = graphql`
  query ($id: String!) {
    wordpressPost(id: {eq: $id}) {
      id
      wordpress_id
      title
      date
      slug
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
        name
        slug
      }
      sector {
        name
        slug
      }
      author
      excerpt
      acf {
        author_name
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        content_blocks_post {
          __typename
          ... on WordPressAcf_content {
            content
          }
          ... on WordPressAcf_image {
            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_blockquote {
            blockquote
          }
          ... on WordPressAcf_video {
            video
            poster {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
          }
          ... on WordPressAcf_video_id {
            youtube_video_id
			video_format
            poster_image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressCategory(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allWordpressWpSector(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
