import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import moment from 'moment'

import BlogCard from './cards/blog-card'

class RelatedPosts extends Component {
	getImage(image) {
		if (image === null || !image.localFile) return
		return image.localFile.childImageSharp.fixed.src
	}

	render() {
		let { data } = this.props

		let staticPosts = this.props.data.allWordpressPost.edges
		let customPosts = this.props.customPosts

		if (customPosts) {
			staticPosts = ''
		} else {
			customPosts = ''
		}

		return (
			<section className="latest-posts">
				<div className="latest-posts__inner container">
					<div className="col col--1of3">
						<h2>Related content</h2>
					</div>
					<div className="col col--2of3 latest-posts__items">
						{
							customPosts &&
							customPosts.map((el, i) => {
								let props = {
									image: this.getImage(el.post.acf.featured_image),
									title: el.post.post_title,
									link: el.post.guid,
									author: el.post.acf.author_name
										? el.post.acf.author_name
										: el.post.post_author,
									date: moment(el.post.acf.post_date).format('MMM, YYYY'),
								}
								return <BlogCard {...props} key={i} />
							})}

						{
							staticPosts &&
							staticPosts.map((el, i) => {
								let props = {
									image: this.getImage(el.node.acf.featured_image),
									title: el.node.title,
									link: `/${el.node.slug}`,
									author: el.node.acf.author_name
										? el.node.acf.author_name
										: el.node.author,
									date: moment(el.node.date).format('MMM, YYYY'),
								}
								return <BlogCard {...props} key={i} />
							})}
					</div>
				</div>
			</section>
		)
	}
}

export default props => (
	<StaticQuery
		query={graphql`
			query relatedPostQuery {
				allWordpressPost(limit: 2, sort: { fields: [date], order: DESC }) {
					edges {
						node {
							title
							date
							slug
							author
							acf {
								author_name
								featured_image {
									localFile {
										childImageSharp {
											fixed(width: 1200) {
												src
											}
										}
									}
								}
							}
						}
					}
				}
				wordpressPage(wordpress_id: { eq: 2 }) {
					title
					acf {
						posts_list {
							post {
								post_title
								wordpress_id
								post_author
								post_date
								post_date_gmt
								post_content
								post_title
								post_excerpt
								post_status
								comment_status
								ping_status
								post_password
								post_name
								to_ping
								pinged
								post_modified
								post_modified_gmt
								post_content_filtered
								post_parent
								guid
								menu_order
								post_type
								post_mime_type
								comment_count
								filter
								acf {
									author_name
									featured_image {
										localFile {
											childImageSharp {
												fixed(width: 1200) {
													src
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <RelatedPosts data={data} {...props} />}
	/>
)
