import React, { Component } from 'react'

import Video from '../video'

class VideoId extends Component {
  render() {
    // Destructure video_format along with the other props
    let { poster_image, youtube_video_id, video_format } = this.props

    // Include video_format in the props object
    let props = {
      poster: poster_image && poster_image.localFile && poster_image.localFile.childImageSharp.original.src,
      video: youtube_video_id,
      autoPlay: true,
      videoFormat: video_format, 
    }
    return (
      <div className='post-block__video-wrapper'>
        <Video {...props} />
      </div>
    )
  }
}

export default VideoId

