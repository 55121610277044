import React, { Component } from 'react'
import axios from 'axios'

class Video extends Component {

  state = {
    video: null,
	posterVisible: true,
  }

  componentDidMount() {
		if(this.props.video)
		{
			let vidId = this.props.video;
			// https://vimeo.com/157715456
			if( vidId.includes('vimeo') )
			{
				vidId = vidId.replace('vimeo.com/', 'player.vimeo.com/video/');
			}
			else
			{
				vidId = `https://www.youtube.com/embed/${this.props.video}`;
			}
			//console.log('vid', this.props.video);
			this.setState({
				video: {
					url: vidId,
				},
			});

			setTimeout(() => {
				this.setState({
					posterVisible: false
				});
			}, 3000);
		}
/*    this.props.video && axios(`https://api.codedrips.com/youtube/${ this.props.video }`)
      .then((response) => {
        let { data } = response
        this.setState({ video: data })
      })
      .catch((e) => console.log(e))*/
  }

  _play() {
    let { video } = this.refs
    if (video && video.readyState === 4) {
      video.play()
    }
  }

  _rewind() {
    let { video } = this.refs;
    if (!video) return
    video.pause()
    video.currentTime = 0
    video.load()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.play !== this.props.play) {
      if (this.props.play) {
        this._play()
      } else {
        this._rewind()
      }
    }
  }

  render() {
    let { video } = this.state
    let { poster, autoPlay, videoFormat } = this.props

	const wrapperClassName = videoFormat 
      ? `video-embed-wrapper video-embed-wrapper--${videoFormat}`
      : 'video-embed-wrapper';

		if( video && video.url.includes('youtube') )
		{
			let src = `${video.url}?mute=1&loop=1&controls=0&modestbranding=1&playlist=${this.props.video}`;
			if( autoPlay )
			{
				src += '&autoplay=1';
			}
			return (
				<div className={wrapperClassName}>
					<div
						style={{
							background: `url(${poster})`,
							backgroundSize: 'cover',
						}}
						className={this.state.posterVisible ? 'poster poster-show' : 'poster poster-hide' }
					>
					</div>
					<iframe frameBorder='0' ref='video' src={src} loop={'loop'} />
				</div>
			)
		}
		else if( video && video.url.includes('vimeo') )
		{
			let src = `${video.url}?muted=1&loop=1&controls=0&title=0&byline=0`;
			if( autoPlay )
			{
				src += '&background=1';
			}
			return (
				<div className={wrapperClassName}>
					<div
						style={{
							background: `url(${poster})`,
							backgroundSize: 'cover',
						}}
						className={this.state.posterVisible ? 'poster poster-show' : 'poster poster-hide' }
					>
					</div>
					<iframe frameBorder='0' ref='video' src={src} loop={'loop'} />
				</div>
			)
		}
		else
		{
			return <video ref='video' src={video && video.url} poster={poster} autoPlay={autoPlay ? 'autoplay' : false} muted={'muted'} playsInline={'playsinline'} loop={'loop'} />
		}
  }

}

export default Video
