import React, { Component } from 'react'

class Image extends Component {
  render() {
    let { image, caption, image_caption } = this.props
    let source = image && image.localFile && image.localFile.childImageSharp.original.src
    return (
      <div className="post-block post-block--image">
        <img className="post-block__image" src={source} alt={image && image.alt_text} />
        { image_caption &&
          <p className="post-block__caption">{caption}</p>
        }
      </div>
    )
  }
}

export default Image
